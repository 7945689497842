import { Outlet, useNavigation } from "@remix-run/react";
import { Logo } from "~/components/logo";
import SelectLanguage from "~/components/select-language";
import { Spinner } from "~/components/spinner";
import { cn } from "~/utils";

export const AuthLayout = () => {
	const navigation = useNavigation();
	const isLoading = navigation.state !== "idle";

	return (

			<div className="flex flex-col gap-4 p-4 content-center h-full">
                <div className="m-auto flex flex-col items-center gap-4">
				<div>
					<Logo className="w-full" />
				</div>
				<div className="relative flex w-full max-w-lg flex-col items-center justify-center rounded-md bg-muted p-4 shadow-lg">
					<div className="flex w-full justify-end"></div>
					<div
						className={cn(
							"h-full w-full",
							isLoading && "invisible"
						)}
					>
						{<Outlet />}
					</div>
					<div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
						{isLoading && <Spinner />}
					</div>
				</div>
				<div className="flex w-full max-w-xs items-center justify-center">
					<SelectLanguage />
				</div>
                </div>
			</div>
	);
};

export default AuthLayout;
